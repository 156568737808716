import React, { useEffect, useState } from 'react';
import SmallShield from '../../Usables/SmallSheild';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from 'recharts';

const DynamicBarChart = ({ chartData, shield_data }) => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const chartHeight = Math.min(windowHeight * 0.6, 600);
  const footerHeight = 60;

  const CustomBarLabel = (props) => {
    const { x, y, width, value } = props;

    if (isNaN(value) || value === undefined) return null;
    
    return (
      <text
        x={x + width + 5}
        y={y + 15}
        fill="white"
        fontSize="12"
      >
        {`${Math.round(value)}%`}
      </text>
    );
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const validPayload = payload.filter(item => !isNaN(item.value));
      if (validPayload.length === 0) return null;

      const { popupData, total } = validPayload[0].payload;
      const numericTotal = isNaN(total) ? 0 : Math.round(total);

      return (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          minWidth: '300px',
          overflow: 'hidden',
          borderRadius: '0.5rem',
          backgroundColor: '#fff',
          color: '#000',
          zIndex: 1000,
        }}>
          <div style={{ fontSize: '1.125rem', fontWeight: 'bold', padding: '0.5rem', borderBottom: '1px solid #1D4ED8' }}>
            {label} {!isNaN(numericTotal) ? `${numericTotal}%` : ''}
          </div>
          <div>
            {popupData?.map((item, index) => {
              const numericValue = isNaN(item.value) ? 0 : Math.round(item.value);
              return (
                <div
                  key={index}
                  style={{ padding: '0.5rem', borderBottom: '1px solid #1D4ED8', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  <span style={{ paddingRight: '1rem', fontSize: '0.875rem', whiteSpace: 'normal' }}>{item.text}</span>
                  <span style={{ fontSize: '0.875rem', fontWeight: 'bold', whiteSpace: 'nowrap' }}>
                    {!isNaN(numericValue) ? `${numericValue}%` : ''}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
    return null;
  };

  const getValidWaveKeys = (categoryData) => {
    if (!categoryData || !Array.isArray(categoryData)) return [];
    const waves = new Set();
    
    categoryData.forEach(item => {
      Object.keys(item).forEach(key => {
        if (key.startsWith('w') && !isNaN(item[key])) {
          waves.add(key);
        }
      });
    });
    
    return Array.from(waves);
  };

  const categories = Object.keys(chartData.data);

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      height: `${chartHeight}px`,
      width: '100%',
      '@media (max-width: 768px)': {
        flexDirection: 'column',
        height: 'auto',
      },
    }}>
      <div style={{
        width: '92.7%',
        height: '100%',
        '@media (max-width: 768px)': {
          width: '100%',
        },
      }}>
        <div style={{
          height: `calc(100% - ${footerHeight}px)`,
          display: 'flex',
          gap: '0.5rem',
          '@media (max-width: 768px)': {
            flexDirection: 'column',
          },
        }}>
          {categories.map((category) => {
            const categoryData = chartData.data[category];
            const validWaves = getValidWaveKeys(categoryData);

            return (
              <div
                key={category}
                style={{
                  flex: 1,
                  boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
                  height: '100%',
                }}
              >
                <div
                  style={{
                    backgroundColor: chartData.colors.headerBackground || '#10B981',
                    color: 'white',
                    padding: '0.5rem',
                    textAlign: 'center',
                    fontWeight: '600',
                    marginBottom: '1rem',
                  }}
                >
                  {category}
                </div>

                <ResponsiveContainer width="100%" height="90%">
                  <BarChart
                    data={categoryData}
                    layout="vertical"
                    margin={{ top: 20, right: 30, left: 90, bottom: 5 }}
                  >
                    <XAxis
                      type="number"
                      domain={[0, 100]}
                      tick={{ fill: 'white' }}
                      axisLine={{ stroke: 'white' }}
                    />
                    <YAxis
                      dataKey="name"
                      type="category"
                      width={80}
                      tick={{ fill: 'white', fontSize: 12 }}
                      axisLine={{ stroke: 'white' }}
                    />
                    <Tooltip
                      content={<CustomTooltip />}
                      cursor={{ fill: 'rgba(255, 255, 255, 0.1)' }}
                    />
                    <Legend
                      wrapperStyle={{ color: 'white' }}
                      formatter={(value) => <span style={{ color: 'white' }}>{value}</span>}
                    />
                    {validWaves.map((waveKey) => (
                      <Bar
                        key={waveKey}
                        dataKey={waveKey}
                        fill={chartData.colors.bars[waveKey]}
                        name={chartData.labels[waveKey]}
                      >
                        <LabelList 
                          content={<CustomBarLabel />}
                          position="right"
                          dataKey={waveKey}
                        />
                      </Bar>
                    ))}
                  </BarChart>
                </ResponsiveContainer>
              </div>
            );
          })}
        </div>

        <div style={{
          height: `${footerHeight}px`,
          padding: '1rem',
          textAlign: 'center',
          color: 'white',
          fontWeight: '500',
        }}>
         {((Object.keys(chartData)[0]).replace("total", ""))} - (n={Object.values(chartData)[0] || 0})
        </div>
      </div>

      <div style={{
        width: '7.3%',
        height: '100%',
        position: 'relative',
        '@media (max-width: 768px)': {
          width: '100%',
          height: '100px',
        },
      }}>
        {shield_data && (
          <div style={{ 
            position: 'absolute', 
            bottom: 0, 
            left: 0, 
          }}>
            <SmallShield 
              scale='80%'
              waveTxt={shield_data.waveTxt}
              fill={shield_data.fill}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DynamicBarChart;