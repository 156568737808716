import React from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import total_icon from '../../imgs/icons/sections/total_icon.svg'
import experience_icon from '../../imgs/icons/sections/experience_icon.svg'
import others_icon from '../../imgs/icons/sections/others_icon.svg'
import branch_icon from '../../imgs/icons/sections/branch_icon.svg'
import relationship_manager_icon from '../../imgs/icons/sections/relationship_manager_icon.svg'
import engagement_icon from '../../imgs/icons/sections/engagement_icon.svg';
import clients_at_risk_icon from '../../imgs/icons/sections/clients_at_risk_icon.svg'
import ShieldButton from '../Usables/ShieldButton.js';
import SectionBar from '../Usables/SectionBar.js';
import ppb_icon from '../../imgs/icons/ppb_icon.svg';
import Flag from '../Usables/Flag.js';
import constants from '../Usables/Constants.js';
import CapitalFirstLetter from '../Usables/CapitalFirstLetter.js';

const Section = () => {
    let location = useLocation();
    localStorage.setItem('currentIndex', 0)
    const navigate = useNavigate();
    const { section } = useParams();

    const handleSelectResult = (result) => {
        navigate(`/${section}/${result}`, { state:{ country:location.state.country, user:location.state.user, report:constants.reports[section] } });
    };

    const section_title = section === 'ppb'? 'PERSONAL & PRIVATE BANKING' : 'BUSINESS & COMMERCIAL BANKING';

    const svg_height = 230;
    const svg_width = 180;

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
            {/* <h2>{section.toUpperCase()} Section</h2> */}
            <div style={{ position: 'absolute', right: 10, top: 60 }}>
                <Flag 
                    img={location.state.country === 'Overall' 
                    ? constants.other_flags['Country Comparison'].img 
                    : constants.country_flags[CapitalFirstLetter(location.state.country)].img} 
                    country={location.state.country.toUpperCase()} 
                />
            </div>
            <SectionBar icon={ppb_icon} imgSize={100} Text={section_title} />
            <div className='circles_cont flexing center_v_and_h' style={{ width: '85%' }}>
                <div className='btn' onClick={() => handleSelectResult('total-results')}>
                    <ShieldButton img_icon={total_icon} svgHeight={svg_height} svgWidth={svg_width} />
                    <div className='shield-label'>{section.toLocaleUpperCase()} TOTAL NPS RESULTS</div>
                </div>
                <div className='btn' onClick={() => handleSelectResult('experience')}>
                    <ShieldButton img_icon={experience_icon} svgHeight={svg_height} svgWidth={svg_width} />
                    <div className='shield-label'>STANDARD BANK EXPERIENCE</div>
                </div>
                <div className='btn' onClick={() => handleSelectResult('others')}>
                    <ShieldButton img_icon={others_icon} svgHeight={svg_height} svgWidth={svg_width} />
                    <div className='shield-label'>OTHER BANKS</div>
                </div>
                {section==='ppb' && ['Zambia', 'Malawi'].includes(location.state.country) && (//, 'Zimbabwe' 
                <div className='btn' onClick={() => handleSelectResult('branch')}>
                    <ShieldButton img_icon={branch_icon} svgHeight={svg_height} svgWidth={svg_width} />
                    <div className='shield-label'>BRANCH</div>
                </div>
                )}
                {section==='bcb' && (
                <div className='btn' onClick={() => handleSelectResult('engagement')}>
                    <ShieldButton img_icon={engagement_icon} svgHeight={svg_height} svgWidth={svg_width} />
                    <div className='shield-label'>PRODUCTS & CHANNEL ENGAGEMENT</div>
                </div>
                )}
                {section==='bcb' && (
                <div className='btn' onClick={() => handleSelectResult('clients_at_risk')}>
                    <ShieldButton img_icon={clients_at_risk_icon} svgHeight={svg_height} svgWidth={svg_width} />
                    <div className='shield-label'>CLIENTS AT RISK OF DEFECTING</div>
                </div>
                )}
                {['Zambia', 'Malawi', 'Kenya', 'Uganda', 'Namibia' ].includes(location.state.country) && (
                <div className='btn' onClick={() => handleSelectResult('relationship-manager')}>
                    <ShieldButton img_icon={relationship_manager_icon} svgHeight={svg_height} svgWidth={svg_width} />
                    <div className='shield-label'>RELATIONSHIP MANAGER</div>
                </div>
                )}
                {section==='ppb' && ['Kenya','Ghana'].includes(location.state.country) && (
                <div className='btn' onClick={() => handleSelectResult('wealth')}>
                    <ShieldButton img_icon={total_icon} svgHeight={svg_height} svgWidth={svg_width} />
                    <div className='shield-label'>WEALTH</div>
                </div>
                )}
            </div>
            
            
        </div>
    );
};

export default Section;
