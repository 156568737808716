import React, { useEffect, useState } from "react";
import ExcelViewer from "../Usables/ExcelViewer";
import PdfViewer from "../Usables/PdfViewer";
import constants from "../Usables/Constants";
// import { get_latest_year } from "./useDataFetcher";
import { fetchData } from "../../services/authService";

const API_URL = constants.API_URL;

const get_latest_year = async (report, setYear) => {
    const url = `files_repo/get_latest_year_with_data/${report}/`;
    try {
        const response = await fetchData(url);
       
        
        setYear(response.latest_year);
        return setYear(response.latest_year);
    } catch (error) {
        console.error('Error fetching data: ', error);
    }
    return setYear(new Date().getFullYear());
}

const OverallFiles = ({report}) => {
    const fileTypes = ['pdf', 'excel'];
    const [whichFile, setWhichFile] = useState(fileTypes[0]);
    const [year, setYear] = useState(new Date().getFullYear());
    const [pdf_url, setPdf_url] = useState(``);
    const [excel_url, setExcel_url] = useState(``);
    

    useEffect(() => {
        const a = async () => {
            await get_latest_year(report, setYear).then(
                ()=>{
                    setPdf_url(`${API_URL}files_repo/files/${report}/${year}/1/pdf/`);
                    setExcel_url(`${API_URL}files_repo/files/${report}/${year}/1/excel/`);
                }
            );
            
        }
        a();
    }, [report, year]);

    // const pdf_url = `http://127.0.0.1:8000/api/files_repo/files/${report}/2024/1/pdf/`;
    // const excel_url = `http://127.0.0.1:8000/api/files_repo/files/${report}/2024/1/excel/`;

    const changeFile = (fileType) => {
        if (fileType!==whichFile) {
            setWhichFile(fileType);
        }
    };

    return (
        <div style={{width:'100%', height:'93%'}}>
            <div style={{marginBottom:'1%'}}>
                <span 
                    className="fileBtn" onClick={() => changeFile(fileTypes[0])}
                    style={fileTypes[0]===whichFile? {fontWeight:'bold', textDecoration:'underline'}: {}}
                >PDF Data Preview</span>
                <span 
                    className="fileBtn" onClick={() => changeFile(fileTypes[1])}
                    style={fileTypes[1]===whichFile? {fontWeight:'bold', textDecoration:'underline'}: {}}
                >Excel Data Preview</span>
            </div>
            {whichFile==='pdf' ?
                (
                    <div style={{width:'100%', height:'100%', border:'1px solid transparent', overflow: 'scroll'}}>
                    {/* <h1>PDF Preview</h1> */}
                    <PdfViewer fileUrl={pdf_url} />
                    </div> 
                ):
                (
                    <div  style={{width:'100%', height:'100%', border:'1px solid transparent', overflow: 'scroll'}}>
                        {/* <h1>Embed Excel in React</h1> */}
                        <ExcelViewer url={excel_url} />
                    </div>
                )
            }
            
        </div>
    )
}

export default OverallFiles;