import React from 'react';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, LabelList } from 'recharts';
import SmallShield from '../../Usables/SmallSheild';
import WeightedNote from '../../Usables/WeightedNote';

const side_space = 10;

const legendData = [
  { name: "Detractors", color: "#D7443F" },
  { name: "Passives", color: "#F4B62F" },
  { name: "Promoters", color: '#33AA52' },
].reverse();

function CustomBarChart({ data, sample_size_data,   shield_data , nps}) {
  const is_weighted = data.some(obj => obj.name.includes('*'))
  let isGraphWave1 = false;
  let isGraphWave2 = false;
  let waveToShow;
  let bar_width = 150;
  
  data.forEach(item => {
    // Check if any of the Wave1 properties exist
    if ('Wave 1_Detractors' in item || 'Wave 1_Passives' in item || 'Wave 1_Promoters' in item) {
        isGraphWave1 = true;
        waveToShow = "Wave 1";
    }
    // Check if any of the Wave2 properties exist
    if ('Wave 2_Detractors' in item || 'Wave 2_Passives' in item || 'Wave 2_Promoters' in item) {
        isGraphWave2 = true;
        if (waveToShow === "Wave 1") {
            waveToShow = "both";
        } else {
            waveToShow = "Wave 2";
        }
    }
});

function getWaveData(name, wave) {
  const category = nps.find(item => item['name'] === wave)?.[name];
  if (!category) {
    console.error(`Category with name "${name}" not found.`);
    return null;
  }
  return category;
}


const CustomTick = (props) => {
  const { x, y, payload } = props;
  const tickData = sample_size_data.find(item => item.name === payload.value);
  
  // Return null if no tick data is found
  if (!tickData) return null;

  // Define a consistent styling for the text
  const textStyle = { fill: "#fff", fontSize: "12px", textAnchor: "middle" };

  return (
    <>
      {waveToShow !== "both" && waveToShow !== undefined && (
        <>
          <text x={x} y={y} dy={16} style={textStyle}>
            {waveToShow}
          </text>

          <text x={x} y={y+16} dy={16} style={{ ...textStyle}}>
          (n={tickData[waveToShow] ? tickData[waveToShow] : 0} )
          </text>


          <text x={x} y={y + 32} dy={16} style={textStyle}>
            {payload.value}
          </text>
        </>
      )}

      {waveToShow === "both" && (
        <>
          <text x={x - 25} y={y} dy={16} style={{ ...textStyle }}>
            Wave 1
          </text>

          <text x={x -30} y={y+16} dy={16} style={{ ...textStyle}}>
          (n={tickData['Wave 1'] ? tickData['Wave 1'] : 0})
          </text>

          <text x={x + 25} y={y} dy={16} style={{ ...textStyle}}>
            Wave 2
          </text>

          <text x={x + 30} y={y+16} dy={16} style={{ ...textStyle}}>
          (n={tickData["Wave 2"] ? tickData["Wave 2"] : 0})
          </text>

          <text x={x} y={y + 32} dy={16} style={{ ...textStyle }}>
            {payload.value}
          </text>
        </>
      )}
    </>
  );
};

  return (
    <div style={{ display:'flex',  height: '90%', padding: '0px'}}>
      <ResponsiveContainer width="100%">
        <BarChart data={data} margin={{ top: 20, bottom: 50 }} formatter={(value) => `${value}%`}>
          
          <XAxis dataKey="name" tick={<CustomTick />} padding={{ right: side_space, left: side_space }} stroke='white'  tickMargin={0} interval={0} />
          <YAxis tickFormatter={(value) => `${value}%`} stroke='white' domain={[0, (dataMax) => (dataMax > 100 ? 100 : dataMax)]}/>

                    
          {isGraphWave1 && (
            <>
            <Bar dataKey="Wave 1_Detractors" stackId="Wave 1" fill="#D7443F" name="Wave 1 Detractors" barSize={bar_width}>
              <LabelList dataKey="Wave 1_Detractors" position="center" formatter={(value) => `${value}%`} style={{ fill: 'white', fontSize: '12px' }} />
            </Bar>

            <Bar dataKey="Wave 1_Passives" stackId="Wave 1" fill="#F4B62F" name="Wave 1 Passives"  barSize={bar_width}>
              <LabelList dataKey="Wave 1_Passives" position="center" formatter={(value) => `${value}%`} style={{ fill: 'white' , fontSize: '12px'}} />
            </Bar>

            <Bar dataKey="Wave 1_Promoters" stackId="Wave 1" fill="#33AA52" name="Wave 1 Promoters"  barSize={bar_width}>
              <LabelList dataKey="Wave 1_Promoters" position="center" formatter={(value) => `${value}%`} style={{ fill: 'white' , fontSize: '12px' }} />
              <LabelList dataKey="name" position="top" formatter={(value) => {const waveValue = getWaveData(value, "Wave 1");  return  waveValue && `NPS = ${waveValue}`}} style={{ fill: 'white' , fontSize: '12px' }} />
            </Bar>
            </>
          )}
          
            {isGraphWave2 && (
            <>
              <Bar dataKey="Wave 2_Detractors" stackId="Wave 2" fill="#D7443F" name="Wave 2 Detractors"  barSize={bar_width}>
                <LabelList dataKey="Wave 2_Detractors" position="center" formatter={(value) => `${value}%`} style={{ fill: 'white' , fontSize: '12px'}} />
              </Bar>

              <Bar dataKey="Wave 2_Passives" stackId="Wave 2" fill="#F4B62F" name="Wave 2 Passives"  barSize={bar_width}>
                <LabelList dataKey="Wave 2_Passives" position="center" formatter={(value) => `${value}%`} style={{ fill: 'white', fontSize: '12px' }} />
              </Bar>

              <Bar dataKey="Wave 2_Promoters" stackId="Wave 2" fill="#33AA52" name="Wave 2 Promoters"  barSize={bar_width}>
                <LabelList dataKey="Wave 2_Promoters" position="center" formatter={(value) => `${value}%`} style={{ fill: 'white', fontSize: '12px' }} />
                <LabelList dataKey="name" position="top" formatter={(value) =>{const waveValue = getWaveData(value, "Wave 2");  return waveValue && `NPS = ${waveValue}`}} style={{ fill: 'white' , fontSize: '12px' }} />
              </Bar>
            </>
            )}

        </BarChart>
      </ResponsiveContainer>
      <div style={{ 
            display: 'flex', 
            flexDirection: 'column', // Aligns children in a column
            justifyContent: 'space-between', // Spaces children with space between them
            boxSizing: 'border-box', // Ensure padding is included in the height/width
          }}>
            <div style={{ marginTop: '30px', alignContent: 'center', marginLeft: '15px' }}>
              {legendData.map((item, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ width: '15px', height: '15px', backgroundColor: item.color }} />
                  <div style={{ color: 'white', marginRight: '5px', paddingLeft: '5px' }}>{item.name}</div>
                </div>
              ))}
            </div>

            <div style={{ 
              display: 'flex', 
              justifyContent: 'flex-end',
              alignItems: 'flex-end',     
            }}>
              <SmallShield 
                scale = '80%'
                fill = {shield_data.fill}
                waveTxt={shield_data.waveTxt}
              />
            </div>
          </div>
          {is_weighted &&
              <WeightedNote />
          }
    </div>
    )};
  

export default CustomBarChart;