import React, { useState } from 'react';
import './styles/globals.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Navigate } from 'react-router';
import { isAuthenticated } from './utils/auth';
import Login from './components/Auth/Login';
import Home from './components/Home/Home';
import ChooseCountry from './components/Home/ChooseCountry';
import Section from './components/Section/Section';
import OverallFiles from './components/Results/OverallFiles';
import PPBTotalResults from './components/Results/PPBTotalResults';
import Experience from './components/Results/Experience';
import Others from './components/Results/Others';
import Branch from './components/Results/Branch';
import RelationshipManager from './components/Results/RelationshipManager';
import Wealth from './components/Results/Wealth';
import AppWrapper from './AppWrapper';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('isLoggedIn') === 'true');

  return (
    <Router>
      <AppWrapper>
        <Routes>
          <Route path="/" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
          <Route path="/home" element={isAuthenticated() ? <Home /> : <Navigate to="/" />} />
          <Route path="/choose-country" element={isAuthenticated() ? <ChooseCountry /> : <Navigate to="/" />} />
          <Route path="/:section" exact element={isAuthenticated() ? <Section /> : <Navigate to="/" />} />

          <Route path="/ppb/Overall" element={isAuthenticated() ? <OverallFiles report={1} /> : <Navigate to="/" />} />

          <Route path="/ppb/total-results" element={isAuthenticated() ? <PPBTotalResults /> : <Navigate to="/" />} />
          <Route path="/ppb/experience" element={isAuthenticated() ? <Experience /> : <Navigate to="/" />} />
          <Route path="/ppb/others" element={isAuthenticated() ? <Others /> : <Navigate to="/" />} />
          <Route path="/ppb/branch" element={isAuthenticated() ? <Branch /> : <Navigate to="/" />} />
          <Route path="/ppb/relationship-manager" element={isAuthenticated() ? <RelationshipManager /> : <Navigate to="/" />} />
          <Route path="/ppb/wealth" element={isAuthenticated() ? <Wealth /> : <Navigate to="/" />} />


          <Route path="/bcb/Overall" element={isAuthenticated() ? <OverallFiles report={2} /> : <Navigate to="/" />} />

          <Route path="/bcb/total-results" element={isAuthenticated() ? <PPBTotalResults /> : <Navigate to="/" />} />
          <Route path="/bcb/experience" element={isAuthenticated() ? <Experience /> : <Navigate to="/" />} />
          <Route path="/bcb/others" element={isAuthenticated() ? <Others /> : <Navigate to="/" />} />
          <Route path="/bcb/engagement" element={isAuthenticated() ? <PPBTotalResults /> : <Navigate to="/" />} />
          <Route path="/bcb/clients_at_risk" element={isAuthenticated() ? <PPBTotalResults /> : <Navigate to="/" />} />
          <Route path="/bcb/relationship-manager" element={isAuthenticated() ? <RelationshipManager /> : <Navigate to="/" />} />
        </Routes>
      </AppWrapper>
    </Router>
  );
};

export default App;
