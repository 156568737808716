// frontend/src/services/authService.js
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import constants from "../components/Usables/Constants";

const API_URL = constants.API_URL;

export const login = async (email, password) => {
    try {
        const response = await axios.post(`${API_URL}user_auth/login/`, { email, password });
        localStorage.setItem("access_token", response.data.access);
        localStorage.setItem("refresh_token", response.data.refresh);
        return response.data;
    } catch (error) {
        console.error("Login failed:", error);
        return null;
    }
};

// export const refreshAccessToken = async () => {
//     try {
//         const refresh_token = localStorage.getItem("refresh_token");
//         if (!refresh_token) return null;

//         const response = await axios.post(`${API_URL}token/refresh/`, { refresh: refresh_token });
//         localStorage.setItem("access_token", response.data.access);
//         return response.data.access;
//     } catch (error) {
//         logout();
//         return null;
//     }
// };

export const logout = async () => {
    try {
        const refresh_token = localStorage.getItem("refresh_token");
        if (refresh_token) {
            await axios.post(`${API_URL}/logout/`, { refresh_token });
        }
    } catch (error) {
        console.error("Logout failed:", error);
    }
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
};

// export const getAuthHeaders = async () => {
//     let accessToken = localStorage.getItem("access_token");

//     if (accessToken) {
//         const decoded = jwtDecode(accessToken);
//         const now = Date.now() / 1000;

//         if (decoded.exp < now) {
//             accessToken = await refreshAccessToken();
//         }
//     }

//     return accessToken ? { Authorization: `Bearer ${accessToken}` } : {};
// };

const getAuthHeaders = () => {
    const token = localStorage.getItem("access_token");
    return {
        headers: {
            Authorization: `Bearer ${token}`
        }
    };
};

export const fetchData = async (endpoint) => {
    try {
        const response = await axios.get(`${API_URL}${endpoint}`, getAuthHeaders());
        return response.data;
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};