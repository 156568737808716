import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
// import DataGrid from "react-data-grid";
import '../../styles/excel_data.css';

const ExcelViewer = ({url}) => {
  const [data, setData] = useState([]);
  const [firstRowCellsWithDoubleRow, setFirstRowCellsWithDoubleRow] = useState([]);
  const [cells_spans, set_cells_spans] = useState({});

  useEffect(() => {
    const fetchExcel = async () => {
      try {
        const response = await fetch(
            url
        );
        const blob = await response.blob();
        
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });

          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
            // console.log(jsonData[0]);
            // let count = 0;
            let row1_cells_spans = {};
            let now_spanning = 0;
            let last_i = -1;
            for (const i in jsonData[1]) {
                // const val = jsonData[0][i];
                // if (val === undefined) {
                //     jsonData[0][i] = "";
                // }
                let empty = false;
                if(jsonData[0][i]===undefined){
                    // jsonData[0][i] = "";
                    empty = true;
                    
                }
                if (!empty) {
                    row1_cells_spans[i] = 0;
                    now_spanning = i;
                }
                const difference_from_last = i-last_i-1;
                if (difference_from_last > 0 && i >= 1) {
                        let arr = firstRowCellsWithDoubleRow;
                        arr.push(i-1);
                        setFirstRowCellsWithDoubleRow(arr);
                    // for (let index = last_i; index < i; index++) {
                        // jsonData[1][i-1] = "";
                    // }
                }
                
                row1_cells_spans[now_spanning]++;
                // console.log(`${i}: ${jsonData[0][i]}: ${jsonData[1][i]}: ${difference_from_last}`);
                // count++;
                last_i = i;
            }
            set_cells_spans(row1_cells_spans);
            // for (const key in jsonData) {
            //     if (key>1) {
            //         console.log(key);
            //         let count = 0;
            //         let last_i = -1;
            //         for (const i in jsonData[key]) {
            //             const difference_from_last = i-last_i-1;
            //             if (difference_from_last > 0 && i > 1) {
            //                 // let end_i = (i===1)
            //                 // for (let index = last_i; index < i; index++) {
            //                 jsonData[1][i-1] = "";
            //                 // }
            //             }
            //             // console.log(`${i}: ${jsonData[key][i]}: ${difference_from_last}`);
            //             count++;
            //             last_i = i;
            //         }
            //     }
            // }
            
          setData(jsonData);
        };

        reader.readAsArrayBuffer(blob);
      } catch (error) {
        console.error("Error fetching the Excel file:", error);
      }
    };

    fetchExcel();
  }, [url, firstRowCellsWithDoubleRow]);

  return (
    <div className="container">
        {/* <h2 className="title">Excel File Preview</h2> */}
        <div className="table-container">
            <table className="table">
            <tbody>
                {data.map((row, rowIndex) => (
                <tr key={rowIndex} className="table-row">
                    {row.map((cell, cellIndex) => (
                    <td 
                        key={cellIndex} className="table-cell" 
                        rowSpan={
                            rowIndex===0 && firstRowCellsWithDoubleRow.indexOf(cellIndex)!==-1 ? 2 : 1
                        }
                        style={{
                            fontWeight: (rowIndex<=1 || firstRowCellsWithDoubleRow.indexOf(cellIndex)!==-1)? 'bold':'normal',
                            borderBottomColor: cell === '?'? 'transparent':'white',
                            borderTopColor: cell === '?'? 'transparent':'white',

                            paddingLeft: cell === '?'? '3rem': '0.5re',
                            fontSize: firstRowCellsWithDoubleRow.indexOf(cellIndex)!==-1 ? '18px': '',
                            textAlign: rowIndex === 0 && Object.prototype.hasOwnProperty.call(cells_spans, cellIndex)? 'center':''
                        }}
                        colSpan={rowIndex === 0 && Object.prototype.hasOwnProperty.call(cells_spans, cellIndex)? cells_spans[cellIndex]: 1}
                    >
                        { !isNaN(parseFloat(cell)) ? Math.round(cell) : (cell === '?')? '': cell}
                    </td>
                    ))}
                </tr>
                ))}
            </tbody>
            </table>
        </div>
    </div>
  );
};

export default ExcelViewer;
