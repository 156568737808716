import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import constants from '../Usables/Constants';
import ChartTitle from '../Usables/ChartTitle';
import '../../styles/filters.css';
import CapitalFirstLetter from '../Usables/CapitalFirstLetter';

import ColumnChart from './Graph/ColumnChart';
import LineChart from './Graph/LineChart';
import StackedColumnChart from './Graph/StackedColumnChart'
import Double_BarCharts_statisfation from './Graph/Double_BarCharts_satisfation';
import DoubleSideBarCharts_experience from './Graph/DoubleSideBarCharts_experience';
import Double_BarChart_relationship from './Graph/Double_BarChart_relationship';
import GridLineCharts from './Graph/Grid_LineCharts';
import NPS_chart from './Graph/Barchart_Reasons'
import Select from 'react-select';


const FilterDropdown = ({ handleFilterChange, setSelected, selected , label, options=[] }) => {

    const selectOptions = options.map((option, index) => ({
        value: option,//(label === 'YEAR:' || label === 'MARKET:') ? option : index,
        label: option
    }));
    
    const market = {
        is: label === 'MARKET:',
        setSelected: setSelected,
        selected: selected
    }
    const handleSelectChange = selectedOption => {
        handleFilterChange(selectedOption.value, setSelected, market);
    };

    return (
        <div className='dropdown_cont'>
            <label className='dropdown_label'>{label}</label>
            <Select 
                className='dropdown' 
                options={selectOptions} 
                onChange={handleSelectChange}
                value={selectOptions.find(option => option.value === selected)}
            />
        </div>
    );
};

const ChildComponent = React.memo(
    React.forwardRef(({ index, isActive, country, chart}, ref) => {
        
        chart.chart_type!=='nps_reason_chart' && console.log("data",chart.data)
        chart.chart_type==='nps_reason_chart' && console.log("main_data",chart.main_data)
        
        return(
        <div
            ref={ref}
            style={{
                height: '100%',
                width: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                transform: `translateY(${isActive ? 0 : 100}%)`,
                transition: 'transform 0.3s ease',
                display: isActive ? 'block' : 'none',
            }}
        >
            <ChartTitle Text={chart.title} Icon={chart.icon} country={country} FillColor = {chart.sectionTitleColor} />
            {((chart.data && chart.data.length>0)  || (chart.main_data && Object.keys(chart.main_data).length>0)) 
            && <div id={'chart'+(index + 1)} style={{ height: '90%', marginTop: '1%' }}>
                {/* Child {index + 1} */}
                {chart.chart_type==='line' &&
                    <LineChart data = {chart.data} lineColors = {chart.colors} shield_data = {chart.shield_data} sample_size = {chart.sample_size_data} />
                }
                {chart.chart_type==='multi_series_stacked_bar' &&
                    <StackedColumnChart data = {chart.data} sample_size_data = {chart.sample_size_data} shield_data = {chart.shield_data} nps = {chart.nps_data}/>
                }
                {chart.chart_type==='column' &&
                    <ColumnChart data = {chart.data} mainColor={'#FFFFFF'} colors = {chart.colors} title = {chart.title}  sample_size_data = {chart.sample_size_data} shield_data = {chart.shield_data} total_data = {chart.total} />
                }
                {chart.chart_type==='satisfaction_bars' &&
                    <Double_BarCharts_statisfation data = {chart.data} shield_data = {chart.shield_data} chart_shield = {chart.chart_shield} sample_size_data = {chart.sample_size_data}/>
                }
                {chart.chart_type==='delivery_exprience_bar' &&
                    <DoubleSideBarCharts_experience data = {chart.data} shield_data = {chart.shield_data} legend_data = {chart.legend_data} sample_size_data = {chart.sample_size_data}/>
                }
                {chart.chart_type==='relationship_sidebars' &&
                    <Double_BarChart_relationship data = {chart.data} sampleSizes = {chart.sample_size_data} shield_data = {chart.shield_data}/>
                }
                {chart.chart_type==='matters_linegraph_with_detail' &&
                    <GridLineCharts allData = {chart.data} shield_data = {chart.shield_data} />
                }
                {chart.chart_type==='nps_reason_chart' &&
                    
                    <NPS_chart chartData = {chart.main_data} shield_data = {chart.shield_data} />
                }
  
            </div>}
        </div>
    )})
);

const ChartsComponent = ({
    location, 
    n_child_divs, 
    charts, 
    filters_data = [], 
    selected_year, 
    set_selected_year, 
    selected_market, 
    set_selected_market, 
    selected_segment, 
    set_selected_segment, 
    handleFilterChange,
    isMarket = true,
    isSegment = true


}) => {
    const parentRef = useRef(null);
    const childRefs = useRef([]);
    const [currentIndex, setCurrentIndex] = useState(parseInt(localStorage.getItem('currentIndex')) || 0);
    
    childRefs.current = Array.from({ length: n_child_divs }, (_, i) => childRefs.current[i] ?? React.createRef());

    useEffect(() => {
        const handleKeyDownAndScroll = (event) => {
            const local_current_i = parseInt(localStorage.getItem('currentIndex'))
            const len_charts = childRefs.current.length
            if (event.key === 'ArrowDown' || event.deltaY >= 0) {
                localStorage.setItem('currentIndex', Math.min(local_current_i + 1, len_charts - 1));
                setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, len_charts - 1));
            } else if (event.key === 'ArrowUp' || event.deltaY < 0) {
                localStorage.setItem('currentIndex', Math.max(local_current_i - 1, 0));
                setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
            }
        };

        window.addEventListener('keydown', handleKeyDownAndScroll);
        window.addEventListener('wheel', handleKeyDownAndScroll);
        
        return () => {
            window.removeEventListener('keydown', handleKeyDownAndScroll);
            window.removeEventListener('wheel', handleKeyDownAndScroll);
        }
    }, []);

    
    // console.log(filters_data);
    
    return (
        <div style={{ height: '90%', width: '90%', margin: 'auto' }}>
            <div className='filters_div'>
                {/* year */}
                <FilterDropdown handleFilterChange={handleFilterChange} setSelected={set_selected_year} selected={selected_year} label="YEAR:" options={filters_data.years} />
                {/* market */}
                {isMarket &&
                    <FilterDropdown handleFilterChange={handleFilterChange} setSelected={set_selected_market} selected={selected_market} label="MARKET:" options={filters_data.markets} />
                }
                {/* segment */}
                {isSegment && 
                <FilterDropdown handleFilterChange={handleFilterChange} setSelected={set_selected_segment} selected={selected_segment} label="SEGMENT:" options={filters_data.segments} />
                }
            </div>
            <div ref={parentRef} style={{ height: '97%', position: 'relative', width: '100%' }}>
                {childRefs.current.map((ref, i) => (
                    <ChildComponent
                        key={i}
                        ref={ref}
                        index={i}
                        isActive={i === currentIndex}
                        country={CapitalFirstLetter(location.state.country)}
                        chart={charts[i]}
                    />
                ))}
            </div>
        </div>
    );
};

export default ChartsComponent;
