import React, { useState, useEffect } from 'react';
import { XAxis, YAxis, ResponsiveContainer, BarChart, Bar, LabelList, Cell } from 'recharts';
import SmallShield from '../../Usables/SmallSheild';

const ColumnChart = ({ data, title , mainColor, colors, sample_size_data, shield_data ,total_data = [], }) => {
  
  const [line, setLine] = useState();
  const [ChartHeight, setChartHeight] = useState();
  const [LegendHeight, setLegendHeight] = useState();
  const [calculatedBarSize, setcalculatedBarSize] = useState();

  let isGraphWave1 = false;
  let isGraphWave2 = false;
  let chartWidth  =100;
  const totalData = [total_data.data];

  useEffect(()=>{
    if (title.includes("RECOMMENDATION")) {
      setLine(false)
      setChartHeight(250)
      setLegendHeight(50)
      setcalculatedBarSize(chartWidth)
    } else {
      setLine(true)
      setChartHeight(250)
      setLegendHeight(100)
      setcalculatedBarSize(chartWidth)
    }

  },[])
 

  data.forEach(item => {
    if ('Wave 1' in item) {
      isGraphWave1 = true;
    }
    if ('Wave 2' in item) {
      isGraphWave2 = true;
    }
  });


  const sampleSizes = sample_size_data[0];
  const numericValues = Object.values(totalData).filter(value => typeof value === 'number');
  const maxNum = Math.max(...numericValues);
  const domain=[0, maxNum+ 5]

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', height: '95%', width: '100%', margin: '10px',  overflow: 'hidden', maxWidth: '100%', maxHeight: '90%' }}>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' ,maxHeight: '100%'}}>

        {/* Main graph container */}
        <div style={{ display: 'flex', alignItems: 'flex-end',  width: '100%', height: '90%'}}>
          
          {line &&
          (<div style={{ width: '15%', borderRight: '1px solid White' }}>
            <ResponsiveContainer height={ChartHeight}>
              <BarChart data={totalData} margin={{ top: 20, right: 0, bottom: 5, left: 0 }} >
                <XAxis 
                  dataKey="name" 
                  tick={{ fill: '#ffffff', fontSize: 12, width: 100 }} 
                  stroke='#ffffff' 
                  height={LegendHeight}
                  interval={0} 
                />
                <YAxis 
                  domain={domain} 
                  tick={false} 
                  axisLine={false} 
                  padding={{ top: 0, bottom: 0 }}  // Ensures there's no padding on the Y-axis
                />
                {isGraphWave1 && (
                <Bar dataKey="Wave 1" barSize={calculatedBarSize}>
                  <Cell fill = {isGraphWave2 ? mainColor: colors[0]}/>
                  
                  <LabelList 
                    dataKey="Wave 1" 
                    position="top" 
                    style={{ fill: '#ffffff' , fontSize:'12px'}} 
                    formatter={line ? null : (value) => `${value}%`}
                  />
                </Bar>)}
                
                {isGraphWave2 && (
                <Bar dataKey="Wave 2"  barSize={calculatedBarSize}>
                  <Cell fill = {colors[0]}/>
                  <LabelList 
                    dataKey="Wave 2" 
                    position="top" 
                    style={{ fill: '#ffffff' , fontSize:'12px'}} 
                    formatter={line ? null : (value) => `${value}%`}
                  />
                </Bar>)}

              </BarChart>
            </ResponsiveContainer>
          </div>)
          }

          {/* Main Graph */}
          <div style={{  width: line ? '80%':'100%' }}> 
            <ResponsiveContainer  height={ChartHeight}>
              <BarChart data={data} margin={{ top: 20, bottom: 5 }} >
                <XAxis 
                  dataKey="name" 
                  tick={{ fill: '#ffffff', fontSize: 12, width: 100 }} 
                  stroke='#ffffff' 
                  height={LegendHeight}
                  interval={0} 
                />
              <YAxis 
                  domain={domain} 
                  tick={false} 
                  axisLine={false} 
                  padding={{ top: 0, bottom: 0 , right:0, left: 0 }}
                  hide
                />
                {isGraphWave1 && (
                  <Bar dataKey="Wave 1" barSize={calculatedBarSize}>
                    {isGraphWave2 ? (
                      data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={mainColor} />
                      ))
                    ) : (
                      data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                      ))
                    )}
                    <LabelList 
                      dataKey="Wave 1" 
                      position="top" 
                      style={{ fill: '#ffffff', fontSize:'12px' }} 
                      formatter={line ? null : (value) => `${value}%`} 
                    />
                  </Bar>
                )}
                {isGraphWave2 && (
                  <Bar dataKey="Wave 2" barSize={calculatedBarSize}>
                    {data.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                    ))}
                    <LabelList 
                      dataKey="Wave 2" 
                      position="top" 
                      style={{ fill: '#ffffff' , fontSize:'12px'}} 
                      formatter={line ? null : (value) => `${value}%`}
                    />
                  </Bar>
                )}
               
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Legend */}
        <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', width: '100%', gap: '10px', marginBottom: '10px' }}>
          {isGraphWave1 && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ width: '15px', height: '15px', backgroundColor: (isGraphWave2  ? '#ffffff': colors[0] )}} />
              <div style={{ color: 'white', marginRight: '5px', paddingLeft: '5px' }}>{`Wave 1 (n = ${sampleSizes['Wave 1']})`}</div>
            </div>
          )}
          {isGraphWave2 && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ width: '15px', height: '15px', backgroundColor: line &&  colors[0] }} />
              <div style={{ color: 'white', marginRight: '5px', paddingLeft: '5px' }}>{`Wave 2 (n = ${sampleSizes['Wave 2']})`}</div>
            </div>
          )}
        </div>
      </div>

      {/* Shield */}
      <div style={{ 
        position: 'relative', 
        display: 'flex', 
        justifyContent: 'flex-end', 
        alignItems: 'flex-end', 
      }}>
        <SmallShield 
          scale='80%'
          waveTxt={shield_data.waveTxt}
          fill={shield_data.fill}
        />
      </div>
    </div>
  );
};

export default ColumnChart;
